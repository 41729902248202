export default {
  appBar: {
    adhdio: 'ADHD.io',
    home: 'Home',
    about: 'About',
    contact: 'Contact',
  },
  banner: {
    adhd: 'ADHD',
    dotIO: '.io',
    subtitle: 'A modern, multifaceted and mobile approach to ADHD',
    comingSoon: 'Coming soon to iOS and Android devices',
  },
  subjectiveTest: {
    title: 'Subjective Measurement Test',
    description: `The ADHD.io app provides an evaluation which will lead to a summary that you can
      immediately review with your child or bring to his or her physician. This summary will
      include the likelihood of having ADHD, how ADHD affects the functionality of your
      child, and possible factors which may mimic this disorder.`,
  },
  objectiveTest: {
    title: 'Objective Measurement Test',
    description: `The next generation of ADHD testing! You may now avoid the hassle and wait for an
      appointment or going to a physical location to take a proctored test. The ADHD.io app
      provides instant access to a modern version of a proven objective assessment at the
      touch of a finger, from the comfort of your home, with immediate results!`,
  },
  testResults: {
    title: 'Unlimited Access',
    description: `You get instant access to easy to interpret summaries! Review recently taken and past
      tests on your mobile device. PDF versions of your results will also be sent to your
      email address for printing out to take to your local practitioner.`,
  },
  multipleUsers: {
    title: 'Multiple Users',
    description: `ADHD is often inherited. In many families parents and siblings may all be struggling
      with the disorder. The ADHD.io app allows you to test and monitor up to 4 family
      members.`,
  },
  contact: {
    title: 'Get in Touch',
    description: `Are you a therapist, psychologist, psychiatrist, or pediatrician? We are seeking
      industry professionals who are interested in partnering with us to help better serve
      their community and patients. You can reach out to us at hello@adhd.io.`,
  },
  footer: {
    copyright: 'Copyright © 2020 ADHD.io',
    siteMap: 'Site Map',
  },
  sitemap: {
    title: 'ADHD.io Site Map',
    adhdio: 'ADHD.io',
    home: 'Home',
    about: 'About',
    contact: 'Contact',
  },
}
