import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Loading from './Loading'
const Home = React.lazy(() => import('./Home'))
const AboutPage = React.lazy(() => import('../pages/AboutPage'))
const ContactPage = React.lazy(() => import('../pages/ContactPage'))
const Sitemap = React.lazy(() => import('../pages/Sitemap'))

export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <div>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/sitemap">
              <Sitemap />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
    </Suspense>
  )
}
